import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/Layout';
import { Section } from '../../components/Section';
import { Calculator, CalculatorAside } from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import SectionTop from '../../components/SectionTop';
import staticData from '../index-data.json';
import './index.scss';

const EssayWriter = () => (
  <Layout className="IndexPage EssayWriter">
    <Helmet>
      <title>Essay Writer with Years of Experience Will Help You with Writing | Ca.CustomWritings.com</title>
      <meta
        name="description"
        content="Need an online essay writer to help you handle academic writing assignments? Visit our website, place your order, and we’ll find the best expert for you. Don’t hesitate to get the help you need."
      />
      <link rel="canonical" href="https://ca.customwritings.com/essay-writer.html" />
      <link rel="alternate" hrefLang="en-ca" href="https://ca.customwritings.com/essay-writer.html" />
      <link
        rel="alternate"
        hrefLang="en"
        href="https://www.customwritings.com/essay-writer.html"
      />
      <link rel="alternate" hrefLang="x-default" href="https://www.customwritings.com/essay-writer.html" />
    </Helmet>

    <SectionTop>
      <h1 className="Section-top__content__title">Get your paper completed by an expert essay writer</h1>
      <span className="Section-top__content__subtitle" />
      <span className="Section-top__content__description">We are here for you 24/7, ready to provide plagiarism-free custom essays of the highest quality</span>
      <a rel="nofollow" href="https://www.customwritings.com/dashboard/orders/create/" className="button">Buy an essay</a>
    </SectionTop>

    <Section className="Section Section-seo">
      <h2 className="Section__heading Section__heading--bubble-1">Looking for a trustworthy essay writer in Canada? We can help!</h2>
      <p>
        Students have to deal with plenty of problems in their daily lives, especially after they get to college. If
        you thought that getting into the institute of your choice would be the most difficult thing in your learning
        career, you’ve been sorely mistaken—it was but an introduction to the real struggle. Whatever workload you had
        in high school pales in comparison to what you have to deal with in university. If you add to this the
        responsibilities that come from acquiring a certain degree of independence, college certainly starts to look
        like a trying experience. With all the writing assignments to deal with and extracurricular activities to keep
        track of, it can be extremely hard to find time for everything, and it’s hardly surprising that many students
        quickly start looking for an essay writing service to help them with at least some of their work.
      </p>
      <p>
        However, if you’re looking for an essay writer, Canada isn’t the most convenient place to be. With the majority of academic assistance companies focusing on the American audience, it may be difficult and time-consuming to find an essay writer familiar with the conventions accepted in Canadian educational institutions. Moreover, among the things studied by the average essay writer, French is rarely the first priority, and many Canadian colleges rely on their students speaking both it and English. Therefore, if you are looking for an online essay writer, you had better stick with services that have experience dealing with Canadian customers—like CustomWritings.com.
      </p>
    </Section>

    <Section className="Section-calculator Section--bg-blue">
      <h2 className="Section__heading">Calculate the price of your paper here</h2>
      <div className="calculator-wrapper">
        <Calculator />
        <CalculatorAside />
      </div>
    </Section>

    <Section className="Section Section-seo">
      <h2 className="Section__heading Section__heading--bubble-2">Looking for a free essay writer? It may not be the best idea</h2>
      <p>
        Many students looking for academic assistance try to find a free essay writer to help them with their work.
        Unfortunately, even in the unlikely event of finding one, using such a service is an extremely dubious idea.
        Creating a high-quality original essay is a difficult, time-consuming process that requires knowledge of the
        discipline in question, the ability to carry out research, and considerable writing skills. Even if you manage
        to get an essay writer free of charge, you can hardly expect a high degree of involvement from him/her. If you
        find an essay writer for free online, he/she will most likely not write your paper at all, but instead borrow
        it from a publicly available online source, which means that you could be accused of plagiarism later on. All
        in all, trying to get an essay writer online free of charge just isn’t worth it. You may save a bit of money
        but get into no end of trouble. Instead of looking for a free online essay writer you should focus on finding
        a reliable and reasonably priced academic assistance service—and this is where we can help you.
      </p>

      <h2 className="Section__heading Section__heading--bubble-3">Hire an affordable online essay writer and forget that you ever had any problems</h2>
      <p>
        CustomWritings.com has been created to help those looking for an essay writer online but unwilling to spend a
        fortune on his/her services. We put a lot of effort into optimizing our workflow to make sure our prices are
        affordable for the absolute majority of students. Today our service is one of the best places in Canada to
        hire a cheap essay writer, and you can be confident you’ll receive exactly what you’ve paid for. Among other
        advantages, we can mention the following:
      </p>
      <ul>
        <li>
          Round-the-clock availability. The need to hire essay writer often arises at the least opportune moments, and
          we understand that our clients can’t always wait for a weekday to place an order. To meet their demands,
          both our customer support team and our writing specialists are available 24/7.
        </li>
        <li>
          Speed. When students purchase academic assistance, they often need an essay writer capable of dealing with
          an urgent task quickly. We address this issue by only hiring people who are capable of working fast without
          any adverse effects on the quality of their texts. 9 out of 10 essays from us are delivered on time.
        </li>
        <li>
          Customization. Open our order form and you’ll see multiple options available to ensure that you can
          fine-tune the instructions to meet your requirements. You can specify the academic level of your helper, so
          if, for example, you hire a master writer, essay will be done according to an especially high-quality
          standard, or you can ask for a native speaker to get even greater linguistic accuracy. You can also request
          copies of all the sources used when writing your paper—and these are just some of the options we offer.
        </li>
      </ul>
    </Section>
    <Section className="Section Section-testimonials Section--bg-blue">
      <h2 className="Section__heading">What customers say about us</h2>
      <Testimonials testimonials={staticData.testimonials} />
    </Section>

    <Section className="Section Section-seo">
      <h2 className="Section__heading Section__heading--bubble-1">Get the best essay writer for your particular order</h2>
      <p>
        When we receive an assignment from a client, we don’t just assign a random person to work on it. We carefully
        go through our database and find the best essay writer to deal with it, taking into account such things as
        his/her skills, academic background, experience, and writing style. Moreover, we are very selective about whom
        we hire to begin with. In other words, when you get a college essay writer from CustomWritings.com to work on
        your paper, he/she is going to be the best of the best. In addition:
      </p>
      <ul>
        <li>
          You can use our essay writer app to easily place an order via your smartphone.
        </li>
        <li>
          If you’re looking for a custom essay writer, there’s hardly a better place than CustomWritings.com—we use
          sophisticated anti-plagiarism software to make sure every paper is written from scratch.
        </li>
        <li>
          Our prices primarily depend on how much time you give us to complete the job. You can get even the most
          experienced essay writer cheaply if you place an order early enough.
        </li>
        <li>
          We provide multiple guarantees (money-back, free revision, confidentiality, etc.) to ensure our customers’
          safety and convenience.
        </li>
      </ul>
      <p>
        As you can see, there’s no need to look for an online essay writer free of charge. We can provide you with as
        much affordable academic assignment writing as you need, and you can be absolutely sure of its quality. Rather
        than look for a free essay writer online, fill in our order form, and our dedicated team of helpers will go
        out of their way to prepare the best possible essay following your requirements to the letter!
      </p>
    </Section>
  </Layout>
);

export default EssayWriter;
